:root {
  --portal-theme-color: #ea7423;
  --portal-theme-secondary: #fbece5;
  --portal-font-family: Montserrat, sans-serif;
  --text-primary-color: #000;
}
html,
body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  font-family: var(--portal-font-family);
}

button {
  color: white;
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--text-primary-color) !important;
  width: 100%;
  padding-left: 16px !important;
  font-size: 14px !important;
}

.menus-list span {
  font-size: 14px !important;
}

.menus-list:hover {
  background-color: var(--portal-theme-secondary) !important;
}
h4 {
  font-size: 25px;
  font-weight: bold;
  color: var(--portal-theme-color);
}
.menuActive {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--portal-theme-color) !important;
  border-right: 3px solid var(--portal-theme-color) !important;
}
/* dashboard */
.dashboard-cards-typography {
  margin-top: 20px;
  font-size: 3rem;
  color: var(--portal-theme-color);
  margin-bottom: 0;
}
.dashboard-card-short-members {
  line-height: 1.57;
  font-size: 0.875rem;
  color: #1939b7;
  margin-bottom: 0;
}
.dashboard-card-short-typography {
  line-height: 1.57;
  font-size: 0.875rem;
  color: #1939b7;
  margin-bottom: 0;
}

.sidebar-icons svg {
  margin-right: 16px;
}
.object-fit-cover {
  object-fit: cover;
}
.password-pin-pic {
  height: 100vh;
}
.custom-text-field {
  border-radius: 8px;
  height: 50px;
}
.forgot-page-side-pic {
  height: 100vh;
  width: 100vw;
}
.admin-login-logo img {
  margin-bottom: 20px;
  width: 50px;
}

.login-button {
  background-color: var(--portal-theme-color);
  color: white;
  height: 40px;
  border: none !important;
  outline: none !important;
  border-radius: 5px;
}
.child-menus-list {
  padding-left: 40px !important;
}
.file-name {
  display: flex;
  text-align: end;
  justify-content: end;
  background-color: #ffe7d9;
  padding: 7px;
  border-radius: 8px;
}

.theme-button-contained {
  background-color: var(--portal-theme-color) !important;
  color: #fff !important;
  padding: 8px 20px;
  text-align: center;
  outline: none !important;
  border: none !important;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}
.resend-button {
  color: white;
  height: 40px;
  border: none !important;
  outline: none !important;
  border-radius: 5px;
  background-color: rgb(248, 90, 90);
}
.cursor-pointer {
  cursor: pointer;
}

.general-mui-modal {
  padding: 16px;
  border-radius: 5px;
  box-shadow: 20;
}

.general-modal-title {
  padding: 0 20px;
  color: var(--portal-theme-color);
  text-align: center;
}
.resend-text {
  cursor: pointer;
  color: var(--portal-theme-color);
  text-decoration: underline;
}
.resource-image-size p {
  font-size: 13px;
}
.resend-text.disabled {
  cursor: not-allowed;
  color: var(--portal-theme-color);
  text-decoration: none;
}
.general-modal-content {
  padding: 10px;
}
.theme-button-member-remove {
  background-color: var(--portal-theme-color);
  color: #fff;
  padding: 8px 20px;
  text-align: center;
  outline: none !important;
  border: none !important;
  border-radius: 5px;
  cursor: pointer;
}
.theme-button-member-remove:disabled {
  /* Styling for the disabled state */
  color: #b0b0b0; /* Light gray text */
  cursor: not-allowed; /* Indicate that the button is not clickable */
  position: relative;
  border: none !important;
  outline: none !important;
}
.p-in-app {
  color: #858585;

  font-size: 13px;
}

.theme-button-member-remove::after {
  /* Add a line or additional styling when disabled */
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
}
.searchField {
  display: flex;
  justify-content: end;
  align-items: end;
  text-align: end;
}
.general-modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--portal-theme-secondary);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.general-modal-close-icon svg {
  font-size: 16px;
}

.app-header {
  background-color: white !important;
  color: black;
  margin: 0;
  padding: 0;
}
.account-menu-option {
  display: flex;
  align-items: center;
}
.account-menu-option svg {
  font-size: 20px;
  margin-right: 10px;
}

.account-menu-option .menu-title {
  font-size: 15px;
}

.account-name h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.account-name p {
  font-size: 0.875rem;
  color: rgb(99, 115, 129);
}
/* programme sesison */
.flex-container {
  display: flex;
  justify-content: center;
}
.description {
  color: black;
  max-height: 80px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: "1px";
}

.flex-item-xs {
  flex-basis: 100%;
  max-width: 100%;
}
.family-members {
}

.flex-item-sm {
  flex-basis: 50%;
  max-width: 50%;
}
.search-container {
  display: flex;
  justify-content: end;
  text-align: end;
  align-items: end;
}

.flex-item-md {
  flex-basis: 33.33%;
  max-width: 33.33%;
}

/* Members Module css */
.member-loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.members_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.members_heading h4 {
  font-size: 25px;
  font-weight: bold;
  color: var(--portal-theme-color);
}
.buttons-div {
  display: flex;
  gap: 15px;
}
.add-recording-drawer {
  gap: 60px;
  align-items: center;
}
.image-preview-add-recording {
  max-width: 70%;
  max-height: 100px;
}
.buttons-div button {
  height: 43px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.buttons-div button svg {
  color: white;
}
.members_card {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
}
.resources-card {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
}
.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
}
.members-table {
  display: flex;
  align-items: center;
}
.members-table button {
  height: 43px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
  margin-left: 15px;
}
.mui-searchfield {
  width: 100%; /* Full width */
  max-width: 500px; /* Adjust as needed */
  align-items: center;
  justify-content: center;
}

.mui-searchfield .MuiOutlinedInput-root {
  height: 48px; /* Adjust height as needed */
  border-radius: 8px; /* Optional: to customize the border radius */
}

.mui-searchfield .MuiInputLabel-root {
  font-size: 15px; /* Adjust font size as needed */
}

.mui-searchfield .MuiSvgIcon-root {
  color: #ea7423; /* Adjust icon color as needed */
}
/* programme resources */
.programmes-resources {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.programmes-resources-content {
  justify-content: space-between;
  display: flex;
}
.programmes-resources-content h4 {
  font-size: 25px;
  font-weight: bold;
  color: var(--portal-theme-color);
}
.programmes-resources-content button {
  height: 43px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
/* Resources List  */
.resource-content {
  display: flex;
  justify-content: space-between;
}
.status-class {
  text-align: center;
}
/* styles.css */
.resource-status {
  font-weight: bold;
  font-size: 12px;
  border-radius: 10px;
  padding: 2px 2px;
  width: 60px;
}

.resource-status.active {
  background-color: rgba(84, 214, 44, 0.16);
  color: #4caf50;
}

.resource-status.inactive {
  background-color: #fadcdb;
  color: #f44336;
}
.recording-catogeryContent {
  display: flex;
  align-items: center;
}
.recordingCatogoryList {
  display: flex;
  align-items: center;
}
.recordingCatogery {
  display: flex;
  justify-content: end;
}
.recording-catogery button {
  height: 43px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.button-addcatogory {
  height: 43px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
  margin-left: 10px;
}

.searchfieldRecordingCatogery {
  width: 100%; /* Full width */
  max-width: 500px; /* Adjust as needed */
  align-items: center;
  justify-content: center;
}
.add-button {
  height: 45px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
/* programmes */
.programmes {
  align-items: center;
}
.programmes button {
  height: 43px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
  max-width: 500px;
}
.programme-add button {
  height: 45px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.cardprogrammes-card {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 30px;
}
/*  */
.buttonportal-style {
  height: 45px;
  border: none;
  background-color: var(--portal-theme-color);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.dashboard-welcome-heading h4 {
  color: var(--portal-theme-color);
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
}
.dashboad-h3 {
  margin-top: 20px;
  font-size: 3rem;
  color: var(--portal-theme-color);
}
.detail-card-img {
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
}
.program-detai-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-card-item h6 {
  color: var(--portal-theme-color);
  font-size: 40px;
}
/* Custom Link Css start  */
.custom-link {
  text-decoration: none;
  color: var(--portal-theme-color);
}
.custom-link:hover {
  color: var(--portal-theme-color);
}
/* Custom Link Css end  */

.resources-image-guide {
  color: #858585;
  font-size: 15px;
}
.lessons-card {
  border-radius: 15px;
  min-height: 250px;
  background-color: #ffe7d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
}

.card-programmes {
  border-radius: 15px;
  min-height: 250px;
  background-color: #fff7cd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
}
.crad-members {
  border-radius: 15px;
  min-height: 250px;
  background-color: #d0f2ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
}
.amount-icon {
  background-color: #bacbf4;
  width: 64px;
  height: 64px;
}
.amount-icon svg {
  font-size: 40px;
  color: #193987;
}
.group-icon {
  width: 64px;
  height: 64px;
  background-color: #aed7f2;
}
.group-icon svg {
  font-size: 30px;
}
.dashboard-h5 {
  font-weight: bold;
  color: var(--portal-theme-color);
}
.programmes-icon {
  width: 64px;
  height: 64px;
  background-color: #f2e2a9;
}
.programmes-icon svg {
  font-size: 30px;
}
.lesson-icon {
  width: 64px;
  height: 64px;
  background-color: #f3c6be;
}
.lesson-icon svg {
  font-size: 30px;
}
.card-total-amount {
  border-radius: 15px;
  min-height: 250px;
  background-color: #d6e4ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
}
/* app setting  */
.app-setting-h4 {
}
.upload-button-style {
  background-color: var(--portal-theme-color);
  align-items: center;
  border: none;
  padding: 6px 16px;
  border-radius: 5px;
}
/* Family Members */

/* custom mui table  classes start */

.mui-without-bg-custom-table {
  background-color: #000;
  border: 1px solid #fff;
  white-space: nowrap;
}

.mui-without-bg-custom-table .MuiPaper-root,
.mui-without-bg-custom-table .Mui-selected,
.mui-without-bg-custom-table .MuiTableRow-hover:hover {
  background-color: #000;
}
.mui-without-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
}

.mui-without-bg-custom-table .MuiCheckbox-root {
  padding: 0;
}

.mui-without-bg-custom-table .MuiPaper-elevation {
  background-color: transparent;
}

.mui-without-bg-custom-table .MuiOutlinedInput-root {
  border: 1px solid #8f8f8f;
}

.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: var(--portal-theme-color);
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: 400;
}
.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.MuiTablePagination-root p,
.MuiTablePagination-root button {
  /* color: #fff !important; */
}

.manage-program-chip-success {
  width: 83px;
  text-align: center;
}
.bagde-image {
  display: inherit;
  height: auto;
  width: 25px;
  margin-left: 10px;
}
.card-with-background {
  background-color: var(--sidebars-background-color);
  border: none;
}
.card-with-background .MuiPaper-root,
.card-with-background .Mui-selected,
.card-with-background .MuiTableRow-hover:hover {
  background-color: rgba(145, 158, 171, 0.08);
}

.table-pagination-box p {
  margin-top: 0 !important;
}

/* custom muitable classes end  */

.forms-drawer .MuiDrawer-paper {
  /* background-color: #092329 !important; */
  /* background-color: var(--portal-theme-color) !important; */
  max-width: 100%;
  width: 400px;
}
.link {
  color: var(--portal-theme-color);
  text-decoration: none;
}
.custom-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.drawer-cross-icon {
  background-color: #16343c !important;
  background-color: var(--portal-theme-secondary) !important;
}

.drawer-cross-icon svg {
  color: var(--portal-theme-color);
}

.drawer-title {
  color: var(--portal-theme-color);
  margin: 0;
}

.custom-drawer-content {
  padding: 16px;
  flex-grow: 1; /* Ensure it takes up remaining space */
  max-height: 85vh;
  overflow: auto;
}

.custom-popover-icon {
  color: var(--portal-theme-color);
  fill: var(--portal-theme-color);
}

.general-mui-modal {
  width: 444px;
  max-width: 95%;
}

.resource-pdf {
  width: 95%;
}

svg {
  color: var(--portal-theme-color);
  fill: var(--portal-theme-color);
}

.program-list-icon {
  background-color: var(--portal-theme-color);
  padding: 12px;
  margin-left: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.program-list-icon svg {
  color: white;
}

.program-card {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 4px 8px 6px rgb(242, 239, 239);
  display: flex;
  flex-direction: column;
  height: auto; /* Fixed height for the card */
  border-radius: 8px; /* Rounded corners */
}
.card.program-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.program-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.card-bogy {
  flex: 1;
  padding: 10px;
}

.recent-members-card {
  box-shadow: 2px 8px 15px #dbdce7;
  border-radius: 10px;
}
.recent-lesson-card {
  box-shadow: 2px 8px 15px #dbdce7;
  border-radius: 10px;
}
.program-card h5 {
  color: var(--portal-theme-color);
  font-weight: 700;
  font-size: 1.5rem;
}
/* custom table style start  */
th {
  white-space: nowrap;
}
tr {
  white-space: nowrap;
}
td {
  white-space: nowrap;
}
/* custom table style end  */

.program-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.program-p-tag-recording {
  color: #858585;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
.custom-editor .select-type button {
  color: black !important;
}
#modal-root .select-type button {
  padding: 6px 20px;
  background-color: var(--editor-toolbar-button-background);
  border: 1px solid var(--editor-border-color);
  cursor: pointer;
  color: #000 !important;
}

.theme-button-contained svg {
  color: #fff;
}
.image-guide-text {
  font-size: 13px;
  color: #858585;
}
.admin-defaukt-settings-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.div-gapmembers {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.add-member-p {
  font-size: 12px;
  color: #858585;
}
.add-programme-p {
  font-size: 13px;
  color: #858585;
}
.image-family-member p {
  color: #858585;
  font-size: 13px;
}
.programme-label {
  background-color: var(--portal-theme-color);
  height: 40px;
  width: 120px;
  color: white;
  border-radius: 5px;
}
.programme-label svg {
  color: white;
}
.dashboard-programme-card {
  color: #a05c75;
  margin-bottom: 0;
  font-size: 0.875rem;
}
/* family members section */
.family-members {
}
.narrow-scrollbar::-webkit-scrollbar {
  width: 5px; /* Decrease scrollbar width */
}

.narrow-scrollbar::-webkit-scrollbar-thumb {
  background-color: #000; /* Scrollbar color */
  border-radius: 10px;
}

.narrow-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

.card-members {
  border-radius: 15px;
  min-height: 250px;
  background-color: #d0f2ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
}
.edit-profile-card {
  box-shadow: 2px 2px 2px gray;
  width: 444;
  height: auto;
  display: flex;
}
.preview-container {
  position: relative;
  display: inline-block;
}

.preview-label {
  position: relative;
  z-index: 1;
  background-color: #fff; /* Ensure label is visible */
  padding: 4px;
}

.preview-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0; /* Hide button visually but still accessible */
  width: 100%;
  height: 100%;
}

.date-show {
  cursor: pointer;
}
.edit-profle-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bold {
  cursor: pointer;
}
.drawer-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.drawer-filter-form {
  display: flex;
  flex-direction: column;
}
.image-guide-members {
  color: #858585;
  font-size: 12px;
}
.anchor-style {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
.anchor-style:hover {
  color: #ea7423 !important;
}
.recording-programme {
  color: #858585;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
}
.text-muted {
  margin-left: 10px;
}

.menus-child-items {
  margin-left: 10px !important;
}

.custom-popover {
  overflow-y: auto !important;
}
.program-card img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}
